import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ModalCreateAccount from "../components/ModalCreateAccount";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLayout } from "context/layout";

export default function SignIn() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const { activeToast } = useLayout()
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await api.get("/auth", {
        headers: {
          email: email,
          password: password
        }
      });

      localStorage.setItem("mamba@token", response.data.person_key)
      activeToast("Usuário autenticado com sucesso.", "success")
      navigate("/teams");
    } catch (error) {
      activeToast("Usuário não autorizado, verifique credenciais.", "error")
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  }
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Mamba.io
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#E0A9FA',
                '&:hover': {
                  backgroundColor: '#E0A9FA'
                }
              }}
              onClick={handleSubmit}
              disabled={loading}
            >
              Logar
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: '#C6C6C6',
                '&:hover': {
                  backgroundColor: '#C6C6C6'
                }
              }}
              onClick={e => handleOpenModal(e)}
            >
              Cadastre-se
            </Button>
          </Box>
        </Box>
      </Container>
      <ModalCreateAccount active={openModal} deactivate={() => setOpenModal(false)} /> 
    </>
  );
}