import React, { useState, useEffect } from "react";
import { useEmailValidation, useNameValidation, usePasswordValidation, usePhoneValidation } from "../hooks/useForms";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../context/layout";

function TypographyWrongField({ message }) {
    return (
        <Typography
            color={"red"}
            variant="caption">{message}</Typography>)
}
export default function ModalCreateAccount({ active, deactivate }) {
    const {activeToast} = useLayout()

    document.addEventListener('keyup', function(event) {
        if (event.keyCode === 27) {
            handleClose()
            deactivate()
        }
      });
    const { validateEmail, errorEmail } = useEmailValidation()
    const { validateName, errorName, errorLastname } = useNameValidation()
    const { validateConfirmPassword, validatePassword, errorPassword } = usePasswordValidation()
    const { validatePhoneNumber, errorNumber } = usePhoneValidation()

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    // const {activeToast} = useLayout()

    useEffect(() => {
        setOpen(active);
    }, [active])

    const handleCreateUser = async () => {
        validateEmail(email);
        validateName(name, "firstName");
        validateName(lastname, "lastName");
        validateConfirmPassword(confirmPassword);
        validatePassword(password);
        validatePhoneNumber(cellphone);

        setLoading(true)

        if (
            !errorEmail.trim() &&
            !errorName.trim() &&
            !errorLastname.trim() &&
            !errorNumber.trim() &&
            !errorPassword.trim()) {
            try {
                const response = await api.post("/person", {
                    "name": name,
                    "lastname": lastname,
                    "cellphone": cellphone,
                    "email": email,
                    "password": password
                });

                localStorage.setItem("mamba@token", response.data.person_key)
                if (response.status == 201) {
                    activeToast("Autenticado com sucesso", "success")
                    return navigate("/dashboard");
                }
            } catch (error) {
                activeToast("Não foi possivel completar cadastro, entre em contato com suporte", "error")
            } finally {
                setLoading(false);
            }
        };
    }

    // MODIFICAR CANCELAR E CLOSE
    const handleCancel = () => {
        setName('');
        setLastname('');
        setEmail('');
        setCellphone('');
        setPassword('');
        setConfirmPassword('');
        setOpen(false);
        deactivate()
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "70vw",
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#D68FD6', width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                    <Typography variant="h6" component="div" pl={2} color={"white"}>Cadastrar Conta</Typography>
                    <IconButton onClick={() => handleCancel()}>
                        <CloseIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
                <Grid item container spacing={2} >
                    <Grid item xs={6}>
                        <TextField
                            label="Primeiro Nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorName} />
                    </Grid>
                    <Grid item xs={6} pr={2}>
                        <TextField
                            label="Sobrenome"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorLastname} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorEmail} />
                    </Grid>
                    <Grid item xs={6} pr={2}>
                        <TextField
                            label="Telefone para Contato"
                            value={cellphone}
                            onChange={(e) => setCellphone(e.target.value)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <TypographyWrongField message={errorNumber} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Senha"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorPassword} />
                    </Grid>
                    <Grid item xs={6} pr={2}>
                        <TextField
                            label="Confirmação de Senha"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorPassword} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-end"} mt={2} mb={2} pr={2}>
                    <Button variant="contained" onClick={handleCancel} style={{ marginRight: '8px', backgroundColor: "#c6c6c6" }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleCreateUser} style={{ backgroundColor: '#D68FD6' }}>
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}