import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ListAthletes from "../components/ListAthletes";
import ListTeamChampionship from "../components/ListTeamChampionship";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TeamTabs({ athletes, init, onDeactivateAthlete, onCellAthlete, team_key, handleUpdateAthlete, championships, onDeactivateChampionship }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    init()
  }, [])

  const renderList = React.useCallback(() => {
    return (
      <ListAthletes
        list={athletes}
        onDelete={(athlete) => onDeactivateAthlete({ "athlete_key": athlete.athlete_key, team_key })}
        onClickCell={(value) => {
          onCellAthlete()
          return handleUpdateAthlete(value)
        }} />
    );
  }, [athletes])

  const renderTeamChampionship = React.useCallback(() => {
    return (
      <ListTeamChampionship
        list={championships}
        onDelete={(team) => onDeactivateChampionship(team)}
      />
    );
  }, [championships])

  return (
    <Box sx={{ width: '100%', marginTop: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Atletas" {...a11yProps(0)} />
          <Tab label="Campeonatos" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {renderList()}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {renderTeamChampionship()}
      </CustomTabPanel>
    </Box>
  );
}