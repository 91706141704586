import {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Header from "./Header";
import { useLayout } from "../context/layout";
import { useNavigate } from "react-router-dom";
import ModalConfirmAction from "./ModalConfirmAction";
import Alert from "../components/Alert";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    '& .MuiDrawer-paper': {
      backgroundColor: '#7A7A7A'
    }
  }),
);


export default function CustomDrawer({children}) {
  const {layout, setLayout, toast, deactiveToast} = useLayout();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const {code} = layout;
  
  const navigate = useNavigate()
  useEffect(() => {
    setLayout(list[0]);
  }, []);

  const list = [{
      name: "Times",
      code: "teams",
      icon: <GroupsIcon />
    }, {
      name: "Campeonatos",
      code: "championships",
      icon: <EmojiEventsIcon />
    }, 
  ];

  const bottomItem = {
    name: "Sair",
    code: "sign-out",
    icon: <ExitToAppIcon />
  };

  const handleExit = () => {
    localStorage.clear()
    navigate('/login', {replace: true})
  };

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <Drawer variant="permanent">
        <List>
          {list.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  px: 2.5,
                }}
                onClick={() => {
                  setLayout(item)
                  return navigate(`/${item.code}`)
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 'auto',
                    justifyContent: 'center',
                    backgroundColor: code == item.code ? "#D68FD6" : "#A8A8A8",
                    padding: 1,
                    borderRadius: 2,
                    color: "white"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
        >
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
              }}
              onClick={() => setConfirmLogout(true)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center',
                  backgroundColor: "#A8A8A8",
                  padding: 1,
                  borderRadius: 2,
                  color: "white"
                }}
              >
                {bottomItem.icon}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, paddingInline: 2, paddingRight: 10, paddingLeft: 10, paddingTop: 5 }}>
        <Header />
        <Alert message={toast.message} type={toast.type} openAlert={toast.openAlert} deactivate={() => deactiveToast()}/> 
        {children}
      </Box>
      <ModalConfirmAction
        active={confirmLogout}
        deactivate={() => setConfirmLogout(false)}
        confirmAction={() => handleExit()}
        message={"Deseja sair?"}/>
    </Box>
  );
}