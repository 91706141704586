import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';

export default function ListAthletes({ list, onClickCell, onDelete }) {

	if (list.length === 0) {
		return <Typography component={"span"}>Não possui atletas cadastrados</Typography>
	}

	return (
		<List sx={{ width: '100%', bgcolor: 'background.paper', marginTop: 2 }}>
			{list.map((value) => {
				const { fullname } = value
				const labelId = `checkbox-list-label-${fullname}`;

				return (
					<ListItem
						key={labelId}
						secondaryAction={
							<IconButton edge="end" aria-label="comments" onClick={() => onDelete(value)} >
								<DeleteIcon />
							</IconButton>
						}
						disablePadding
					>
						<ListItemButton role={undefined} onClick={() => onClickCell(value)} dense>
							<ListItemText id={labelId} primary={fullname} />
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
}