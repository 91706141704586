import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function ModalConfirmAccount({ active, deactivate, message, confirmAction, description }) {
	const [open, setOpen] = useState(false);

	document.addEventListener('keyup', function(event) {
        if (event.keyCode === 27) {
          deactivate()
        }
      });
	  
	useEffect(() => {
		setOpen(active);
	}, [active])

	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false);
		}
	};

	const handleCancel = () => {
		setOpen(false);
		deactivate()
	};

	const onConfirmAction = () => {
		confirmAction()
		handleCancel()
	}
	return (
		<Modal
			open={open}
			onClose={handleClose}>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: "70vw",
					bgcolor: 'background.paper',
					borderRadius: '8px',
					boxShadow: 24,
					maxWidth: 400
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#D68FD6', width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
					<Typography variant="h6" component="div" pl={2} color={"white"}>{message}</Typography>
					<IconButton onClick={() => handleCancel()}>
						<CloseIcon sx={{ color: "#fff" }} />
					</IconButton>
				</Box>
				<Grid item container spacing={2} >
					<Grid ml={2}>
						<Typography fontSize={12} >{description}</Typography>
					</Grid>
					<Grid container justifyContent={"flex-end"} mt={2} mb={2} pr={2}>
						<Button variant="contained" onClick={handleCancel} style={{ marginRight: '8px', backgroundColor: "#c6c6c6" }}>
							Cancelar
						</Button>
						<Button variant="contained" color="primary" onClick={() => onConfirmAction()} style={{ backgroundColor: '#D68FD6' }}>
							Sim
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
}