import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListEntities from "components/ListEntities";
import ModalCreateTeam from "components/ModalCreateTeam";
import ModalConfirmAction from "components/ModalConfirmAction";
import { useLayout } from "context/layout";
import { useTeam } from "hooks/useTeam";

export function Teams() {
  const [openModalConfirmDelete, setOpenModalCofirmDelete] = React.useState(false);
  const [entity, setEntity] = React.useState({});

  const {
    teamModal,
    setTeamModal, 
    setLayout
  } = useLayout()
  const navigate = useNavigate()

  const {
    teamList,
    handleGetTeam,
    handleDeactivateTeam
  } = useTeam();

  React.useEffect(() => {
    if (!openModalConfirmDelete) {
      setEntity({})
    }
  }, [openModalConfirmDelete])

  React.useEffect(() => {
    handleGetTeam()
  }, [])

  const handleCellTeam = (value) => {
    const category = value.category === "fem" ? "Feminino" :  "Masculino" 
    setLayout({code:"team", name: value.name, category})
    return navigate("/team", {state: value})
  }

  const renderCreateTeam = React.useCallback(() => {
    return (
      <ModalCreateTeam active={teamModal.createTeam} deactivate={() => setTeamModal({ ...teamModal, createTeam: false })} updateList={() => handleGetTeam()} />
    )
  }, [teamModal.createTeam]);

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ListEntities
              list={teamList}
              onDelete={(value) => {
                setOpenModalCofirmDelete(true)
                setEntity(value)
              }}
              onClickCell={handleCellTeam} />
          </Paper>
        </Grid>
      </Container>
      <ModalConfirmAction
        active={openModalConfirmDelete}
        deactivate={() => setOpenModalCofirmDelete(false)}
        confirmAction={() => handleDeactivateTeam(entity)}
        message={"Confirma deleção?"}
        description={"Uma vez deletados as informações não poderão ser recuperadas"} />
      {renderCreateTeam()}
    </> 
  );
}


export default Teams;