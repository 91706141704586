import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import api from "../utils/api";
import { useBirthplace, useCpf, useNameValidation, useDateBirth, useRegistration, useVoterRegistration, useVoterRegistrationYear } from "../hooks/useForms";
import dayjs from 'dayjs';
import { useLayout } from "context/layout";

function TypographyWrongField({ message }) {
    return (
        <Typography
            color={"red"}
            variant="caption">{message}</Typography>)
}

export default function ModalCreateAthlete({ active, deactivate, updateAthleteList, externalId, currentAthlete, cleanCurrentAthleteState }) {
    document.addEventListener('keyup', function(event) {
        if (event.keyCode === 27) {
          deactivate()
        }
      });
    const [fullname, setFullname] = useState('');
    const [cpf, setCpf] = useState('');
    const [dateBirth, setDateBirth] = useState(dayjs({}));
    const [voterRegistration, setVoterRegistration] = useState('');
    const [voterRegistrationYear, setVoterRegistrationYear] = useState(dayjs({}));
    const [birthplace, setBirthplace] = useState('');
    const [registration, setRegistration] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const [isUpdate, setIsUpdate] = useState(false)
    const { validateName, errorName } = useNameValidation();
    const { errorBirthplace, validateBirthplace } = useBirthplace();
    const { errorCpf, validateCpf } = useCpf();
    const { errorDateBirth, validateDateBirth } = useDateBirth();
    const { errorRegistration, validateRegistration } = useRegistration();
    const { errorVoterRegistration, validateVoterRegistration } = useVoterRegistration();
    const { errorVoterRegistrationYear, validateVoterRegistrationYear } = useVoterRegistrationYear();

    const {activeToast} = useLayout()

    useEffect(() => {
        setOpen(active);
        if (active && currentAthlete && currentAthlete.hasOwnProperty("fullname")) {
            const { fullname, date_birth, cpf, voter_registration, voter_registration_year, registration, birthplace } = currentAthlete
            setFullname(fullname);
            setDateBirth(dayjs(date_birth));
            setCpf(cpf);
            setVoterRegistration(voter_registration);
            setVoterRegistrationYear(dayjs(voter_registration_year));
            setRegistration(registration);
            setBirthplace(birthplace);
            setIsUpdate(true);
        }
    }, [active])

    const handleUpdate = async () => {
        setLoading(true);

        const date = dateBirth.toISOString()
        const year = voterRegistrationYear.year().toString()
        validateName(fullname, "firstName");
        validateBirthplace(birthplace);
        validateCpf(cpf);
        validateDateBirth(date);
        validateRegistration(registration);
        validateVoterRegistration(voterRegistration);
        validateVoterRegistrationYear(year);

        if (!errorBirthplace.trim() &&
            !errorName.trim() &&
            !errorCpf.trim() &&
            !errorDateBirth.trim() &&
            !errorRegistration.trim() &&
            !errorVoterRegistration.trim() &&
            !errorVoterRegistrationYear.trim()) {
            try {
                const response = await api.put("/athlete", {
                    "fullname": fullname,
                    "athlete_key": currentAthlete.athlete_key,
                    "date_birth": date,
                    "cpf": cpf,
                    "voter_registration": voterRegistration,
                    "voter_registration_year": year,
                    "registration": registration,
                    "birthplace": birthplace
                });

                if (response.status == 200) {
                    updateAthleteList(externalId)
                    handleCancel()
                }
            } catch (error) {
                  activeToast("Verifique se os dados estão preenchidos corretamente", "error")
            } finally {
                setLoading(false);
            }
        }
    }

    const handleCreateAthlete = async () => {
        setLoading(true);
        const person_key = localStorage.getItem("mamba@token");

        const date = dateBirth.toISOString()
        const year = voterRegistrationYear.year().toString()
        validateName(fullname, "firstName");
        validateBirthplace(birthplace);
        validateCpf(cpf);
        validateDateBirth(date);
        validateRegistration(registration);
        validateVoterRegistration(voterRegistration);
        validateVoterRegistrationYear(year);

        if (!errorBirthplace.trim() &&
            !errorName.trim() &&
            !errorCpf.trim() &&
            !errorDateBirth.trim() &&
            !errorRegistration.trim() &&
            !errorVoterRegistration.trim() &&
            !errorVoterRegistrationYear.trim()) {
            try {
                const response = await api.post("/athlete", {
                    "fullname": fullname,
                    "person_key": person_key,
                    "date_birth": date,
                    "cpf": cpf,
                    "voter_registration": voterRegistration,
                    "voter_registration_year": year,
                    "registration": registration,
                    "birthplace": birthplace,
                    "external_id": externalId
                });

                if (response.status == 201) {
                    updateAthleteList(externalId)
                    handleCancel()
                }
            } catch (error) {
                activeToast("Verifique se os dados estão preenchidos corretamente", "error")
            } finally {
                setLoading(false);
            }
        }
    }

    const handleCancel = () => {
        setFullname("");
        setBirthplace("");
        setCpf("");
        setDateBirth(dayjs({}));
        setRegistration("");
        setVoterRegistration("");
        setVoterRegistrationYear(dayjs({}));
        setOpen(false);
        cleanCurrentAthleteState()
        deactivate()
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleCancel()
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "70vw",
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#D68FD6', width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                    <Typography variant="h6" component="div" pl={2} color={"white"}>Cadastrar Atleta</Typography>
                    <IconButton onClick={() => handleCancel()}>
                        <CloseIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
                <Grid item container spacing={2} >
                    <TextField
                        label="Nome Completo"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{
                            marginRight: 2,
                            marginLeft: 2
                        }}
                    />
                    <TypographyWrongField message={errorName} />
                    <Grid item xs={6}>
                        <TextField
                            label="CPF"
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorCpf} />
                    </Grid>
                    <Grid item xs={6} pr={2} mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    value={dateBirth}
                                    label="Data de Nascimento"
                                    sx={{ width: "100%" }}
                                    format="DD/MM/YYYY"
                                    onChange={e => {
                                        if (isNaN(e)) return
                                        setDateBirth(dayjs(e.toISOString()))
                                    }} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <TypographyWrongField message={errorDateBirth} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Titulo de eleitor"
                            value={voterRegistration}
                            onChange={(e) => setVoterRegistration(e.target.value)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <TypographyWrongField message={errorVoterRegistration} />
                    </Grid>
                    <Grid item xs={6} pr={2} mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker value={voterRegistrationYear} label="Ano do Titulo de eleitor" sx={{ width: "100%" }} views={['year']} onChange={e => {
                                    if (isNaN(e)) return
                                    setVoterRegistrationYear(dayjs(e.year().toString()))
                                }} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <TypographyWrongField message={errorVoterRegistrationYear} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Registro"
                            value={registration}
                            onChange={(e) => setRegistration(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorRegistration} />
                    </Grid>
                    <Grid item xs={6} pr={2}>
                        <TextField
                            label="Naturalidade"
                            value={birthplace}
                            onChange={(e) => setBirthplace(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorBirthplace} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-end"} mt={2} mb={2} pr={2}>
                    <Button variant="contained" onClick={handleCancel} style={{ marginRight: '8px', backgroundColor: "#c6c6c6" }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={isUpdate ? handleUpdate : handleCreateAthlete} style={{ backgroundColor: '#D68FD6' }} disabled={loading}>
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}