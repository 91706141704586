import { useState } from "react";
import api from "../utils/api";
import { useLayout } from "../context/layout";

export function useTeam() {

  const [teamList, setTeamList] = useState([])
  const [teamChampionshipList, setTeamChampionshipList] = useState([])

  const {activeToast} = useLayout()
  async function handleDeactivateTeam(value) {
    const { team_key } = value
    try {
      const response = await api.delete(`/team/delete/${team_key}`);

      if (response.status == 200) {
        activeToast("Time deletado com sucesso.", "success")
        handleGetTeam()
      }
    } catch (error) {
      activeToast("Não foi possivel deletar time.", "error")
    }
  }

  async function handleGetTeam() {
    const person_key = localStorage.getItem("mamba@token")
    try {
      const response = await api.get(`/team/${person_key}`);

      if (response.status == 200) {
        setTeamList(response.data)
      }
    } catch (error) {
      activeToast("Não foi possivel recuperar registro dos times, entre em contato com suporte", "error")
    }
  }

  async function handleUpdateTeam(value) {
    try {
      const { name, team_key } = value
      const response = await api.put(`/team/update/${team_key}`, {
        "name": name
      });

      if (response.status == 200) {
        activeToast("Nome de time atualizado com sucesso.", "success")
      }
    } catch (error) {
      activeToast("Não foi possivel atualizar o nome do time, entre em contato com suporte", "error");
    }
  }

  async function handleGetChampionships(team_key) {
    try {
      const response = await api.get(`/team/championship?team_key=${team_key}`);

      if (response.status === 200) {
        setTeamChampionshipList(response.data)
      }
    } catch (error) {
      activeToast("Não foi possivel atualizar o nome do time, entre em contato com suporte", "error");
    }
  }

  const handleDeactivateTeamChampionship = async (value) => {
    const { championship_key, team_key } = value
    try {
      const response = await api.patch("/championship/team", {
        "team_key": team_key,
        "championship_key": championship_key,
        "enumerator": "rejected"
      });
      activeToast("Time rejeitado.", "success")
      handleGetChampionships(team_key)
    } catch (error) {
      activeToast("Não foi possivel recuperar os campeonatos cadastrados", "error")
    }
  }

  async function handleParticipateTeamChampionship(value) {
    try {
      const { team_key, external_id } = value
      const response = api.post("/team/championship", {
        team_key,
        external_id
      })
      if (response.status == 201) {
        activeToast("Foi requerido entrada em campeonato aguarde retorno do organizador.", "success")
      }
    } catch (error) {
      activeToast("Problema durante o cadastro de time em campeonato", "error")
    }
  }

  return { 
    teamList, 
    handleDeactivateTeam, 
    handleGetTeam, 
    handleUpdateTeam, 
    teamChampionshipList, 
    handleGetChampionships, 
    handleDeactivateTeamChampionship, 
    handleParticipateTeamChampionship, 
  }
}