import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from '../components/ListItems';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import ListEntities from "../components/ListEntities";
import { useChampinship } from "../hooks/useChampionship";
import { useTeam } from "../hooks/useTeam";
import { useAthlete } from "../hooks/useAthlete";
import ModalCreateChampionship from "../components/ModalCreateChampionship";
import ModalCreateTeam from "../components/ModalCreateTeam";
import ModalConfirmAction from "../components/ModalConfirmAction";
import ModalEdit from "../components/ModalEdit";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TeamTabs from "../components/TeamTabs";
import ChampionshipTabs from "../components/ChampionshipTabs";
import ModalCreateAthlete from "../components/ModalCreateAthlete";
import ModalParticipateChampionship from "../components/ModalParticipateChampionship";
import ModalEditChampionship from "../components/ModalEditChampionship";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import { useLayout } from "../context/layout";
const drawerWidth = 240;

export function Championship() {
  const [open, setOpen] = React.useState(true);
  const [openModalTeam, setOpenModalTeam] = React.useState(false);
  const [openModalChampionship, setOpenModalChampionship] = React.useState(false);
  const [openModalConfirmDelete, setOpenModalCofirmDelete] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [openModalParticipateChampionship, setOpenModalParticipateChampionship] = React.useState(false)
  const [openModalEditChampionship, setOpenModalEditChampionship] = React.useState(false);

  const { 
    entity,
    championshipModal, 
    setChampionshipModal  } = useLayout()

  const { handleGetChampionship, championshipTeamList, handleGetTeamChampionship, championshipTeamPendingList, handleAceptTeamChampionship, handleUpdateChampionship } = useChampinship();
  const { 
    handleDeactivateTeamChampionship, 
  } = useTeam();

  return (
       <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid item xs={12} md={8} lg={9} >
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChampionshipTabs
                init={() => {
                  handleGetTeamChampionship(entity.championship_key, "active")
                  return handleGetTeamChampionship(entity.championship_key, "pending")
                }}
                championshipTeam={championshipTeamList}
                championshipTeamPending={championshipTeamPendingList}
                onDeactivateTeam={(value) => {
                  handleDeactivateTeamChampionship(value)
                  handleGetTeamChampionship(entity.championship_key, "pending")
                  handleGetTeamChampionship(entity.championship_key, "active")
                  return handleGetTeamChampionship(entity.championship_key, "pending")
                }}
                onAcept={(value) => {
                  handleAceptTeamChampionship(value)
                  handleGetTeamChampionship(entity.championship_key, "pending")
                }}
              />
            </Paper>
          </Grid>
        <ModalEditChampionship active={championshipModal.editNameChampionship} deactivate={() => setChampionshipModal({...championshipModal, editNameChampionship: false})} update={(value) => handleUpdateChampionship(value)} updateTeam={() => handleGetChampionship()} championship_key={entity.championship_key} />
        </Container>
    // <Box sx={{ display: 'flex' }}>
    //   {/* <Button onClick={openDrawer}>
    //     zxdfasdfasdf
    //   </Button> */}
    //   <CssBaseline />
    //   <AppBar position="absolute" open={open}>
    //     {/* <Toolbar
    //       sx={{
    //         pr: '24px',
    //         backgroundColor: '#C6C6C6'
    //       }}
    //     >
    //       <IconButton
    //         edge="start"
    //         color="inherit"
    //         aria-label="open drawer"
    //         onClick={toggleDrawer}
    //         sx={{
    //           marginRight: '36px',
    //           ...(open && { display: 'none' }),
    //         }}
    //       >
    //         <MenuIcon />
    //       </IconButton>
    //       <Typography
    //         component="h1"
    //         variant="h6"
    //         color="inherit"
    //         noWrap
    //         sx={{ flexGrow: 1 }}
    //       >
    //         {headerName}
    //       </Typography>
    //       {renderScreenAction()}
    //     </Toolbar> */}
    //   </AppBar>
    //   {/* <Drawer variant="permanent" open={open}>
    //     <Toolbar
    //       sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'flex-end',
    //         px: [1],
    //       }}
    //     >
    //       <IconButton onClick={toggleDrawer}>
    //         <ChevronLeftIcon />
    //       </IconButton>
    //     </Toolbar>
    //     <Divider />
    //     <List component="nav">
    //       <MainListItems list={list} select={(name) => setSelected(name)} />
    //     </List>
    //   </Drawer> */}
    //   <Box
    //     component="main"
    //     sx={{
    //       backgroundColor: (theme) =>
    //         theme.palette.mode === 'light'
    //           ? theme.palette.grey[100]
    //           : theme.palette.grey[900],
    //       flexGrow: 1,
    //       height: '100vh',
    //       overflow: 'auto',
    //     }}
    //   >
    //     {renderDashboard()}
    //   </Box>
     
    //   <ModalEdit active={openModalEdit} deactivate={() => setOpenModalEdit(false)} title={headerName} update={(value) => handleUpdateTeam(value)} updateTeam={() => handleGetTeam()} team_key={entity.team_key} />
    //   <ModalConfirmAction
    //     active={openModalConfirmDelete}
    //     deactivate={() => setOpenModalCofirmDelete(false)}
    //     confirmAction={selected === "Times" ? () => handleDeactivateTeam(entity) : () => handleDeactivateChampionship(entity)}
    //     message={"Confirma deleção?"}
    //     description={"Uma vez deletados as informações não poderão ser recuperadas"} />
    //   <ModalCreateAthlete
    //     active={openModalCreateAthlete}
    //     deactivate={() => setOpenModalCreateAthlete(false)}
    //     updateAthleteList={() => handleGetAthletes(entity.team_key)}
    //     externalId={entity.external_id}
    //     currentAthlete={currenAthleteUpdate} />
    //   <ModalParticipateChampionship active={openModalParticipateChampionship} deactivate={() => setOpenModalParticipateChampionship(false)} onSave={(value) => handleParticipateTeamChampionship(value)} team_key={entity.team_key} />
    //   <Alert message={"Organizador do campeonato notificado"} type={"sucess"} openAlert={participateTeamSucess} deactivate={() => setParticipateTeamSucess(false)}/> 
    //   <Alert message={"Não foi possivel cadastrar o time em campeonato, tente novamente."} type={"error"} openAlert={participateTeamError} deactivate={() => setParticipateTeamError(false)}/> 
    // </Box >
  );
}


export default Championship;