import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNameValidation } from "../hooks/useForms";
import api from "../utils/api";
import Divider from '@mui/material/Divider';
import { useLayout } from "../context/layout";

function TypographyWrongField({ message }) {
    return (
        <Typography
            color={"red"}
            variant="caption"
            mb={1}>{message}</Typography>)
}

export default function ModalCreateChampionship({ active, deactivate, updateList }) {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const {activeToast} = useLayout()

    const { validateName, errorName } = useNameValidation()
    document.addEventListener('keyup', function(event) {
        if (event.keyCode === 27) {
          deactivate()
        }
      });

    useEffect(() => {
        setOpen(active);
    }, [active])

    const handleSave = async () => {
        validateName(name, "firstName");
        setLoading(true)
        const person_key = localStorage.getItem("mamba@token")

        if (!errorName.trim()) {
            try {
                const response = await api.post("/championship", {
                    "name": name,
                    "person_key": person_key
                });

                if (response.status == 201) {
                    activeToast("Campeonato criado com sucesso", "success")
                    updateList()
                    setName("")
                    handleClose()
                }
            } catch (error) {
                  activeToast("Não foi possivel completar cadastro, entre em contato com suporte", "error")
            } finally {
                setLoading(false);
            }
        }
    }

    const handleCancel = () => {
        setName('');
        setOpen(false);
        deactivate();
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleCancel()
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "70vw",
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#D68FD6', width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                    <Typography variant="h6" component="div" pl={2} color={"white"}>Criar Campeonato</Typography>
                    <IconButton onClick={() => handleCancel()}>
                        <CloseIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
                <Grid container direction="column" item width={"100%"}>
                    <TextField
                        label="Nome do Campeonato"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            width: "70%"
                        }}
                        margin="normal"
                    />
                    <TypographyWrongField message={errorName} />
                    <Divider />
                </Grid>
                <Grid container justifyContent={"flex-end"} mt={2} mb={2} pr={2}>
                    <Button variant="contained" onClick={handleCancel} style={{ marginRight: '8px', backgroundColor: "#c6c6c6" }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave} style={{ backgroundColor: '#D68FD6' }}>
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}