import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
export default function ListChampionshipTeamPending({ list, onDelete, onAcept }) {

	if (list.length === 0) {
		return <Typography component={"span"}>Não possui times cadastrados para o campeonato</Typography>
	}

	return (
		<List sx={{ width: '100%', bgcolor: 'background.paper', marginTop: 2 }}>
			{list.map((value) => {
				const { team_name } = value
				const labelId = `checkbox-list-label-${team_name}`;

				return (
					<ListItem
						key={labelId}
						secondaryAction={
							<>
								<IconButton edge="end" aria-label="comments" sx={{ marginRight: 2 }} onClick={() => onAcept(value)} >
									<CheckBoxIcon sx={{ color: '#68CCB8' }} />
								</IconButton>
								<IconButton edge="end" aria-label="comments"  onClick={() => onDelete(value)}>
									<CloseIcon sx={{ color: '#D49081' }} />
								</IconButton>
							</>
						}
						disablePadding
					>
						<ListItemText id={labelId} primary={team_name} />
					</ListItem>
				);
			})}
		</List>
	);
}