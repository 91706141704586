import {useCallback, useEffect} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

// "success" | "error"
export default function AlertCustom({type, message, openAlert, deactivate}) {
    const vertical = "top";
    const horizontal = "right";

    function SlideTransition(props) {
        return <Slide {...props} direction="left" />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        deactivate()
    };

    return (
        <Snackbar 
            open={openAlert} 
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={SlideTransition}>
            <Alert
                onClose={handleClose}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
            </Alert>
        </Snackbar>
    );
}