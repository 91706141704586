import { useState } from 'react';

// Hook para validação de e-mail
export function useEmailValidation() {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const validateEmail = (value) => {
    setEmail(value);
    if (!value.trim()) {
      setErrorEmail('Por favor, preencha o campo de e-mail.');
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setErrorEmail('Por favor, insira um endereço de e-mail válido.');
    } else {
      setErrorEmail('');
    }
  };

  return { email, errorEmail, validateEmail };
}

// Hook para validação de senha
export function usePasswordValidation() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const validatePassword = (value) => {
    setPassword(value);
    if (value.length < 8) {
      setErrorPassword('A senha deve ter pelo menos 8 caracteres.');
    } else if (value !== confirmPassword) {
      setErrorPassword('As senhas não coincidem.');
    } else {
      setErrorPassword('');
    }
  };

  const validateConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (value !== password) {
      setErrorPassword('As senhas não coincidem.');
    } else {
      setErrorPassword('');
    }
  };

  return { password, confirmPassword, errorPassword, validatePassword, validateConfirmPassword };
}

// Hook para validação de nome e sobrenome
export function useNameValidation() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorLastname, setErrorLastname] = useState('');

  const validateName = (value, type) => {
    if (type === 'firstName') {
      setFirstName(value);
    } else if (type === 'lastName') {
      setLastName(value);
    }

    if (!value.trim()) {
      type === 'firstName' ?
        setErrorName("Por favor, preencha o campo de nome") :
        setErrorLastname("Por favor, preencha o campo de sobrenome")
    } else {
      setErrorName('');
      setErrorLastname('');
    }
  };

  return { firstName, lastName, errorName, errorLastname, validateName };
}

// Hook para validação de número de telefone
export function usePhoneValidation() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorNumber, setErrorNumber] = useState('');

  const validatePhoneNumber = (value) => {
    setPhoneNumber(value);
    if (!value.trim()) {
      setErrorNumber('Por favor, preencha o campo de número de telefone.');
    } else {
      setErrorNumber('');
    }
  };

  return { phoneNumber, errorNumber, validatePhoneNumber };
}

export function useCategory() {
  const [errorCategory, setErrorCategory] = useState('');

  const validateCategory = (value) => {
    if (!value.trim()) {
      setErrorCategory('Por favor, preencha o campo de número de categoria.');
    } else {
      setErrorCategory('');
    }
  }

  return { validateCategory, errorCategory }
}

export function useCpf(value) {
  const [errorCpf, setErrorCpf] = useState("")

  const validateCpf = (value) => {
    if (!value.trim()) {
      setErrorCpf('Por favor, preencha o campo de cpf.');
    } else {
      setErrorCpf('');
    }
  }

  return { errorCpf, validateCpf }
}

export function useDateBirth(value) {
  const [errorDateBirth, setErrorDateBirth] = useState("")

  const validateDateBirth = (value) => {
    if (!value.trim()) {
      setErrorDateBirth('Por favor, preencha o campo de data de nascimento.');
    } else {
      setErrorDateBirth('');
    }
  }

  return { errorDateBirth, validateDateBirth }
}

export function useVoterRegistration(value) {
  const [errorVoterRegistration, setErrorVoterRegistration] = useState("");

  const validateVoterRegistration = (value) => {
    if (!value.trim()) {
      setErrorVoterRegistration('Por favor, preencha o campo de titulo de eleitor.');
    } else {
      setErrorVoterRegistration('');
    }
  };

  return { errorVoterRegistration, validateVoterRegistration };
}

export function useVoterRegistrationYear(value) {
  const [errorVoterRegistrationYear, setErrorVoterRegistrationYear] = useState("");

  const validateVoterRegistrationYear = (value) => {
    if (!value.trim()) {
      setErrorVoterRegistrationYear('Por favor, preencha o campo do ano de titulo de eleitor.');
    } else {
      setErrorVoterRegistrationYear('');
    }
  };

  return { errorVoterRegistrationYear, validateVoterRegistrationYear };
}

export function useBirthplace(value) {
  const [errorBirthplace, setErrorBirthplace] = useState("");

  const validateBirthplace = (value) => {
    if (!value.trim()) {
      setErrorBirthplace('Por favor, preencha o campo de local de nascimento.');
    } else {
      setErrorBirthplace('');
    }
  };

  return { errorBirthplace, validateBirthplace };
}

export function useRegistration(value) {
  const [errorRegistration, setErrorRegistration] = useState("");

  const validateRegistration = (value) => {
    if (!value.trim()) {
      setErrorRegistration('Por favor, preencha o campo de registro.');
    } else {
      setErrorRegistration('');
    }
  };

  return { errorRegistration, validateRegistration };
}
