import React from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useLayout } from "../context/layout";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

function Header() {

    const {
      layout, 
      teamModal, 
      setTeamModal, 
      championshipModal, 
      setChampionshipModal,
      entity
    } = useLayout()

    const renderAction = React.useCallback(() => {
        const {code} = layout
        if (code == "teams" || code == "championships") {
          return (
            <Button variant="contained" sx={{
              backgroundColor: "#E0A9FA", '&:hover': {
                backgroundColor: "#E0A9FA"
              }
            }}
              onClick={
                code === "teams" ? () => setTeamModal({...teamModal, createTeam: true}): () => setChampionshipModal({...championshipModal, createChampionship: true})} 
              >
              <AddBoxIcon />
              <Typography>Criar</Typography>
            </Button>
          )
        }

        if (code === "team") {
          return (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button variant="contained" sx={{
                backgroundColor: "#E0A9FA", '&:hover': {
                  backgroundColor: "#E0A9FA"
                },
              }}
                onClick={() => setTeamModal({...teamModal, participateChampionship: true})} >
                <Typography mr={1}>Participar de Campeonato</Typography>
                <EmojiEventsIcon />
              </Button>
              <Button 
              variant="contained" sx={{
                backgroundColor: "#E0A9FA", '&:hover': {
                  backgroundColor: "#E0A9FA"
                },
                marginRight: 5,
                marginLeft: 5
              }}
                onClick={() => setTeamModal({...teamModal, createAthlete: true})} >
                <Typography mr={1}>Cadastrar atleta</Typography>
                <DirectionsRunIcon />
              </Button>
              <Button variant="contained" sx={{
                backgroundColor: "#E0A9FA", '&:hover': {
                  backgroundColor: "#E0A9FA"
                }
              }}
                onClick={() => setTeamModal({...teamModal, editTeamName: true})} >
                <Typography mr={1}>Editar</Typography>
                <ModeEditIcon />
              </Button>
            </Box>
          )
        }
    
        if (code == "championship") {
          return (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Typography sx={{
                padding: 1,
                borderRadius: 2,
                marginRight: 2,
                backgroundColor: 'black',
                color: "white"
              }}>
                {entity.external_id}
              </Typography>
              <Button variant="contained" sx={{
                backgroundColor: "#E0A9FA", '&:hover': {
                  backgroundColor: "#E0A9FA"
                }
              }}
                onClick={() => setChampionshipModal({...teamModal, editNameChampionship: true})} 
                >
                <Typography mr={1}>Editar</Typography>
                <ModeEditIcon />
              </Button>
            </Box>
          );
        }
      }, [layout])
    
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >   
          <Grid flexDirection={"column"}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {layout.name}
            </Typography>
            {layout.category && (<Typography variant="h8" sx={{ fontWeight: 'bold', color: "#c5c5c5" }}>
              {layout.category}
            </Typography>)}
          </Grid>
            {renderAction()}
        </Box>
      );
}

export default Header;