import { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import {LayoutProvider} from "./context/layout";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalDrawer from "./components/Drawer";
import Teams from "./pages/Teams";
import Team from "./pages/Team";
import Championship from "./pages/Championship";
import Championships from "./pages/Championships";

const defaultTheme = createTheme();

export default function App() {

  const verificarCredenciais = () => {
    return localStorage.getItem('mamba@token') !== null;
  };

  const VerificarAutenticacao = () => {
    if (verificarCredenciais()) {
      return <Navigate to="/teams" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  const PrivateRoute = ({ children }) => {
    if (verificarCredenciais()) {
      return (
          <GlobalDrawer>
            {children}
          </GlobalDrawer>
      );
    } else {
      return <Navigate to="/login" replace />;
    }
  };
  
  return (
    <ThemeProvider theme={defaultTheme}>
        <LayoutProvider>
        <BrowserRouter>
          <Routes >
            <Route path="/" element={<VerificarAutenticacao />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/teams" element={<PrivateRoute><Teams /></PrivateRoute>} />
            <Route path="/championships" element={<PrivateRoute><Championships /></PrivateRoute>} />
            <Route path="/championship" element={<PrivateRoute><Championship /></PrivateRoute>} />
            <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />
          </Routes>
        </BrowserRouter>
        </LayoutProvider>
    </ThemeProvider>
  );
}