import React, { useState, useEffect } from "react";
import { useNameValidation } from "../hooks/useForms";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useLayout } from "context/layout";

function TypographyWrongField({ message }) {
    return (
        <Typography
            color={"red"}
            variant="caption">
            {message}
        </Typography>)
}

export default function ModalEditChampionship({ active, deactivate, update, updateTeam, championship_key }) {
    document.addEventListener('keyup', function(event) {
        if (event.keyCode === 27) {
          deactivate()
        }
      });
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { validateName, errorName, } = useNameValidation()
    const { activeToast, setLayout, layout} = useLayout()
    
    useEffect(() => {
        setOpen(active);
    }, [active])

    const handleSave = async () => {
        validateName(name, "firstName");
        setLoading(true)

        if (!errorName.trim()) {
            try {
                update({ name, championship_key })
                updateTeam()
                setLayout({...layout, name})
                handleCancel()
            } catch (error) {
                activeToast("Não foi possivel completar edição, entre em contato com suporte", "error")
            } finally {
                setLoading(false);
            }
        }
    }

    const handleCancel = () => {
        setName('');
        setOpen(false);
        deactivate()
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "70vw",
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#D68FD6', width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                    <Typography variant="h6" component="div" pl={2} color={"white"}>Editar Time</Typography>
                    <IconButton onClick={() => handleCancel()}>
                        <CloseIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
                <Grid item container spacing={2} alignItems={"center"}>
                    <Grid item xs={7}>
                        <TextField
                            label="Nome do time"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TypographyWrongField message={errorName} />
                    </Grid>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: 2 }} />
                <Grid container justifyContent={"flex-end"} mt={2} mb={2} pr={2}>
                    <Button variant="contained" onClick={handleCancel} style={{ marginRight: '8px', backgroundColor: "#c6c6c6" }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave} style={{ backgroundColor: '#D68FD6' }} disabled={loading}>
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}