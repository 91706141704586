import { useState } from "react";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLayout } from "context/layout";

export function useAthlete() {

	const [athletes, setAthletes] = useState([])
	const {activeToast} = useLayout()

	const handleGetAthletes = async (team_key) => {
		try {
			const response = await api.get(`/team/athlete?team_key=${team_key}&enumerator=active`);
			if (response.status == 200) {
				setAthletes(response.data)
				activeToast("Lista de atletas cadatrados recuperada com sucesso.", "success")
			}
		} catch (error) {
			activeToast("Não foi possivel recuperar os atletas cadastrados", "error")
		}
	}

	const handleDeactivateAthlete = async (value) => {
		const { athlete_key, team_key } = value
		try {
			const response = await api.patch("/team/athlete", {
				"athlete_key": athlete_key,
				"team_key": team_key,
				"enumerator": "rejected"
			});
			handleGetAthletes(team_key)
		} catch (error) {
			activeToast("Não foi possivel recuperar os atletas cadastrados", "error")
		}
	}

	return { athletes, handleGetAthletes, handleDeactivateAthlete }
}