import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from '../components/ListItems';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import ListEntities from "../components/ListEntities";
import { useChampinship } from "../hooks/useChampionship";
import { useTeam } from "../hooks/useTeam";
import { useAthlete } from "../hooks/useAthlete";
import ModalCreateChampionship from "../components/ModalCreateChampionship";
import ModalCreateTeam from "../components/ModalCreateTeam";
import ModalConfirmAction from "../components/ModalConfirmAction";
import ModalEdit from "../components/ModalEdit";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TeamTabs from "../components/TeamTabs";
import ChampionshipTabs from "../components/ChampionshipTabs";
import ModalCreateAthlete from "../components/ModalCreateAthlete";
import ModalParticipateChampionship from "../components/ModalParticipateChampionship";
import ModalEditChampionship from "../components/ModalEditChampionship";
import Alert from "../components/Alert";
import { useLayout } from "../context/layout";



export function Team() {
  const [open, setOpen] = React.useState(true);
  const [openModalTeam, setOpenModalTeam] = React.useState(false);
  const [openModalChampionship, setOpenModalChampionship] = React.useState(false);
  const [openModalConfirmDelete, setOpenModalCofirmDelete] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [openModalParticipateChampionship, setOpenModalParticipateChampionship] = React.useState(false)
  const [headerName, setHeaderName] = React.useState("")
  const [openModalCreateAthlete, setOpenModalCreateAthlete] = React.useState(false);
  const [entity, setEntity] = React.useState({});
  const [currenAthleteUpdate, setCurrentAthleteUpdate] = React.useState({});

  const {state: team} = useLocation()
  const {
    teamModal,
    setTeamModal    
  } = useLayout()
  
  const { 
    handleGetChampionships, 
    teamChampionshipList, 
    handleDeactivateTeamChampionship,
    handleParticipateTeamChampionship,
    handleUpdateTeam,
    handleGetTeam
  } = useTeam();

  const { athletes, handleGetAthletes, handleDeactivateAthlete } = useAthlete();

  const createAthlete = React.useCallback(() => {
    return(
      <ModalCreateAthlete
        active={teamModal.createAthlete}
        deactivate={() => setTeamModal({ ...teamModal, createAthlete: false })}
        updateAthleteList={() => handleGetAthletes(team.team_key)}
        externalId={team.external_id}
        currentAthlete={currenAthleteUpdate} 
        cleanCurrentAthleteState={() => setCurrentAthleteUpdate({})}
      />
    )
  }, [teamModal.createAthlete]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid item xs={12} md={8} lg={9} >
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TeamTabs
            athletes={athletes}
            championships={teamChampionshipList}
            init={() => {
              handleGetAthletes(team.team_key)
              return handleGetChampionships(team.team_key)
            }}
            onDeactivateAthlete={(value) => handleDeactivateAthlete(value)}
            onDeactivateChampionship={(value) => handleDeactivateTeamChampionship(value)}
            team_key={team.team_key}
            handleUpdateAthlete={(value) => setCurrentAthleteUpdate(value)}
            onCellAthlete={() => setTeamModal({ ...teamModal, createAthlete: true })} />
        </Paper>
      </Grid>
      {createAthlete()}
      <ModalParticipateChampionship active={teamModal.participateChampionship} deactivate={() => setTeamModal({ ...teamModal, participateChampionship: false })} onSave={(value) => handleParticipateTeamChampionship(value)} team_key={team.team_key} />
      <ModalEdit active={teamModal.editTeamName} deactivate={() => setTeamModal({ ...teamModal, editTeamName: false })} title={headerName} update={(value) => handleUpdateTeam(value)} updateTeam={() => handleGetTeam()} team_key={team.team_key} />
    </Container>
  )
}


export default Team;