import React, { createContext, useState, useContext } from 'react';

const LayoutContext = createContext();

export const useLayout = () => {
  return useContext(LayoutContext);
};

export const LayoutProvider = ({ children }) => {

  const [layout, setLayout] = useState({
    name: "",
    code: ""
  })

  const [toast, setToast] = useState({
    message: "",
    type: "",
    openAlert: false,
  });

  const activeToast = (message, type) => {
    setToast({message, type, openAlert: true})
  }

  const deactiveToast = () => {
    setToast({
      message: "",
      type: "",
      openAlert: false,
    })
  }

  const [entity, setEntity] = useState({})

  const [teamModal, setTeamModal] = useState({
    createTeam: false,
    participateChampionship: false,
    createAthlete: false,
    editTeamName: false
  })

  const [championshipModal, setChampionshipModal] = useState({
    createChampionship: false,
    editNameChampionship: false
  })

  return (
    <LayoutContext.Provider value={{ 
        layout, 
        setLayout,
        teamModal,
        setTeamModal,
        championshipModal, 
        setChampionshipModal,
        activeToast,
        deactiveToast,
        toast,
        entity, 
        setEntity
      }}>
      {children}
    </LayoutContext.Provider>
  );
};