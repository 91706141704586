import { useState } from "react";
import api from "../utils/api";
import { useLayout } from "../context/layout";

export function useChampinship() {

  const [championshipList, setChampionshipList] = useState([])
  const [championshipTeamList, setChampionshipTeamList] = useState([])
  const [championshipTeamPendingList, setChampionshipTeamPendingList] = useState([])

  const {activeToast} = useLayout()

  async function handleDeactivateChampionship(value) {
    const { championship_key } = value
    try {
      const response = await api.delete(`/championship/delete/${championship_key}`);

      if (response.status == 200) {
        handleGetChampionship()
      }
    } catch (error) {
      activeToast("Não foi possivel deletar time, entre em contato com suporte", "error")
    }
  }

  async function handleGetChampionship() {
    const person_key = localStorage.getItem("mamba@token")
    try {
      const response = await api.get(`/championship/${person_key}`);

      if (response.status == 200) {
        setChampionshipList(response.data)
        activeToast("Campeonatos carregados com sucesso", "success")
      }
    } catch (error) {
      activeToast("Não foi possivel recuperar registro dos campeonatos, entre em contato com suporte", "error")
    }
  }

  async function handleGetTeamChampionship(championship_key, enumerator) {
    try {
      const response = await api.get(`/championship/team?championship_key=${championship_key}&enumerator=${enumerator}`);

      if (response.status == 200) {
        if (enumerator == "active") {
          setChampionshipTeamList(response.data)
        } else {
          setChampionshipTeamPendingList(response.data)
        }
        activeToast("Listagem de campeonatos atualizada com sucesso", "success")
      }
    } catch (error) {
      activeToast("Não foi possivel recuperar registro dos campeonatos, entre em contato com suporte", "error")
    }
  }

  async function handleAceptTeamChampionship(value) {
    const { team_key, championship_key } = value
    const response = await api.patch('/championship/team', {
      enumerator: "active",
      team_key,
      championship_key
    });
    if (response.status == 200) handleGetTeamChampionship(championship_key, "active")

  }

  async function handleUpdateChampionship(value) {
    try {
      const { name, championship_key } = value
      const response = await api.put(`/championship/update/${championship_key}`, {
        "name": name
      });

    } catch (error) {
      activeToast("Não foi possivel atualizar o nome do campeonato, entre em contato com suporte", "error")
    }
  }

  return {
    championshipList,
    handleDeactivateChampionship,
    handleGetChampionship,
    championshipTeamList,
    handleGetTeamChampionship,
    championshipTeamPendingList,
    handleAceptTeamChampionship,
    handleUpdateChampionship
  };
}
